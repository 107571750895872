import { APPOINTMENT_LIST, UPCOMING_APPOINTMENT_LIST, CHAT_APPOINTMENT_DETAIL } from "../actions/types"

const initialState = {
    chat_appointment_detail: null,
    appointment_list: null,
    upcoming_appointments: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APPOINTMENT_LIST:
            return {
                ...state,
                appointment_list: payload?.data,
            };
        case CHAT_APPOINTMENT_DETAIL:
            return {
                ...state,
                chat_appointment_detail: payload?.data,
            };
        case UPCOMING_APPOINTMENT_LIST:
            return {
                ...state,
                upcoming_appointments: payload?.data,
            };

        default:
            return state
    }
}