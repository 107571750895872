

import { PRIVACY_POLICY, TERMS_AND_CONDITIONS, AUTO_UPDATE_MESSAGE } from "../actions/types";

const initialState = {
    privacy_policy: null,
    terms_conditions: null,
    auto_update_message: null,
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRIVACY_POLICY:
            return {
                ...state,
                privacy_policy: payload
            }
        case TERMS_AND_CONDITIONS:
            return {
                ...state,
                terms_conditions: payload
            }
        case AUTO_UPDATE_MESSAGE:
            return {
                ...state,
                auto_update_message: payload
            }
        default:
            return state
    }
}