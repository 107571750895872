import { combineReducers } from 'redux';
import users from '../reducers/users';
import commonData from '../reducers/commonData';
import gas_stations from '../reducers/gas_stations';
import appointments from '../reducers/appointments';
import { LOGOUT_SUCCESS } from '../actions/types'

const appReducer = combineReducers({  
    users,   
    commonData,
    gas_stations,
    appointments
});


const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer;