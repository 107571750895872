import {GAS_STATION_LIST,GAS_STATION_LIST_STATS } from "../actions/types"

const initialState = {
    gas_station_list: null,
    gas_station_list_stats: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        
       
        case GAS_STATION_LIST:
            return {
                ...state,
                gas_station_list: payload?.data,
            };
        case GAS_STATION_LIST_STATS:
            return {
                ...state,
                gas_station_list_stats: payload?.data,
            };

        default:
            return state
    }
}