import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Typography } from 'antd';
import { Provider } from "react-redux"
import store from "./store"

import './assets/css/style.css';
import './assets/css/responsive.css';
import projectlogo from "./assets/img/logo/logo.png";
import { getFcmToken } from './firebase';

const AppRouter = lazy(() => import('./routes'))

window.addEventListener('popstate', function (e) {
  localStorage.removeItem("other_User_Id")
  localStorage.removeItem("other_User_Detail")
});

window.addEventListener('pagehide', function (event) {
  // when the user navigates away from the page, including when they close the tab or window.
  localStorage.removeItem("other_User_Id")
  localStorage.removeItem("other_User_Detail")
});


const App = () => {
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    const temp = async () => {
      localStorage.setItem("fcm", await getFcmToken(setTokenFound))
    }
    temp();
  }, [])
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh" }}>
            <Typography className='suspense_text'> <span style={{ color: "" }}>Loading</span> <span style={{ color: "#EF2F3A" }}>Frontline</span> Please<span style={{ color: "#EF2F3A" }}> wait....</span></Typography>
            <div style={{ textAlign: "center", margin: "1rem 0" }}>
              <img src={projectlogo} className="suspense_logo" />
            </div>
          </div>
        }
      >
        <AppRouter />
      </Suspense>
    </Provider>
  );
}
export default App;
