import { LOGIN_SUCCESS, USER_DETAILS, LOGOUT_SUCCESS, IS_COLLAPSED, USERS_LIST, DASHBOARD_DATA } from "../actions/types"

const initialState = {
    token: '',
    isLoggedIn: false,
    is_collapsed: false,
    user_detail: null,
    dashboard_data: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                token: payload?.data?.token,
                isLoggedIn: true,
                user_detail: payload?.data,
            };
        case USER_DETAILS:
            return {
                ...state,
                isLoggedIn: true,
                user_detail: payload?.data,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case IS_COLLAPSED:
            return {
                ...state,
                is_collapsed: payload?.is_collapsed,
            };
        case USERS_LIST:
            return {
                ...state,
                users_list: payload?.data,
            };
        case DASHBOARD_DATA:
            return {
                ...state,
                dashboard_data: payload?.data,
            };

        default:
            return state
    }
}