import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDzdmm2m5jjZcpr63s8DRCcHDj1JlQ3yIQ",
    authDomain: "frontlinefinish-3ddd6.firebaseapp.com",
    projectId: "frontlinefinish-3ddd6",
    storageBucket: "frontlinefinish-3ddd6.appspot.com",
    messagingSenderId: "231974542493",
    appId: "1:231974542493:web:38c2213ef64916b2404874",
    measurementId: "G-6TV12TQE38"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const REACT_APP_VAPID_KEY = "BLCHXpWgX-OX901Via-xahDy4TQ-vXYLD0HTikNUEYFE-wywpaUtC86_j2qCA9DLl8sfNHgCkVfhiSaJp_FAywg"
const publicKey = REACT_APP_VAPID_KEY;

export const getFcmToken = async (setTokenFound) => {
    let currentToken = '';
    try {
        currentToken = await getToken(messaging, { vapidKey: publicKey });;
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log('An error occurred while retrieving token ::::: ', error);
    }
    return currentToken;
};